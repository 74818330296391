import React from "react"
import "../../../styles/course.scss"
import CourseNav from "../../../components/course/Nav"
import Footer from "../../../components/course/sections/Footer"
import SEO from "../../../components/seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import Section from "../../../components/course/Section"

const Contest = ({
  data,
}: {
  data: {
    courseCover: any
    poloznaFormularz: any
    poloznaSms: any
    poloznaPotwierdzenie: any
  }
}) => {
  const {
    courseCover,
    poloznaFormularz,
    poloznaSms,
    poloznaPotwierdzenie,
  } = data
  return (
    <div id={"course-container"}>
      <SEO
        title={"Wspieramy Anię Nowak-Dudę"}
        description={
          "Jesteś kursantką Szkoły Rodzenia Anny Nowak-Dudy? Zagłosuj na nia w konkursie Położna na Medal."
        }
        image={courseCover.childImageSharp.fluid.src}
        article={false}
      />
      <CourseNav />
      <Section className="is-white" id="main">
        <div className="container is-widescreen">
          <div className="columns is-vcentered">
            <div className="column is-6-desktop is-offset-3-desktop">
              <div className="container">
                <h1 className="title is-3 has-text-black-ter has-text-centered mb-4">
                  <strong>
                    Wspieramy Anię w plebiscycie "Położna na Medal"!
                  </strong>
                </h1>
                <Paragraph>
                  Nasza ukochana położna Anna Nowak-Duda została zgłoszona do
                  plebiscytu "Położna na Medal".
                </Paragraph>
                <Paragraph>
                  Jesteś zadowoloną kursantką Szkoły Rodzenia Ani, a może któryś
                  z artykułów lub filmów pomógł Ci lepiej przygotowac się do
                  porodu i macierzyństwa?
                </Paragraph>
                <h2 className="title is-4 has-text-font-comfortaa has-text-black-ter has-text-centered mb-4">
                  <strong className="has-text-primary">
                    Zagłosuj na Anię plebiscycie "Położna na Medal"!
                  </strong>
                </h2>
                <div
                  className={"has-text-centered mb-4 is-4 subtitle"}
                  style={{ marginTop: 10 }}
                >
                  <a
                    href={
                      "https://poloznanamedal.info/profil/1130-anna-nowak-duda"
                    }
                  >
                    https://poloznanamedal.info/profil/1130-anna-nowak-duda
                  </a>
                </div>
                <Paragraph>
                  Głosowanie zajmie chwilkę i jest{" "}
                  <strong>całkowicie bezpłatne</strong>. Poniżej zrobiłam
                  instrukcję - jak głosować :)
                </Paragraph>

                <h2>Jak zagłosować na Anię?:</h2>
                <List>
                  <li>
                    Podaj swój numer telefonu i adres email w formularzu na
                    stronie:{" "}
                    <a
                      href={
                        "https://poloznanamedal.info/profil/1130-anna-nowak-duda"
                      }
                    >
                      https://poloznanamedal.info/profil/1130-anna-nowak-duda
                    </a>
                    <Img
                      fluid={poloznaFormularz.childImageSharp.fluid}
                      alt={"Formularz głosowania"}
                    />
                  </li>
                  <li>
                    Kliknij link w SMS:{" "}
                    <Img
                      fluid={poloznaSms.childImageSharp.fluid}
                      alt={"Smsm z linkiem potwierdzającym"}
                    />
                  </li>
                  <li>
                    Na koniec powinnaś zobaczyć potwierdzenie oddania głosu:
                    <Img
                      fluid={poloznaPotwierdzenie.childImageSharp.fluid}
                      alt={"Formularz głosowania"}
                    />
                  </li>
                </List>
                <Paragraph>
                  Dziekuję za wszystkie wasze głosy! Joanna Mikołajczuk.
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Footer />
    </div>
  )
}

export default Contest

const Paragraph = styled.p`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
`

const List = styled.ol`
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;

  & li {
    margin-top: 10px;

    & img {
      padding-top: 10px;
    }
  }
`

export const query = graphql`
  query {
    courseCover: file(relativePath: { eq: "course-cover.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    poloznaFormularz: file(
      relativePath: { eq: "polozna_na_medal_formularz.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    poloznaSms: file(relativePath: { eq: "polozna_na_medal_sms.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    poloznaPotwierdzenie: file(
      relativePath: { eq: "polozna_na_medal_potwierdzenie.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`
